.cancel-body-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 45vw;
    text-align: center;
    margin: 2em 0 3em;
    .img-modal-container {
        width: 4rem;
        height: 4rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
        img {
            width: 100%;
            height: 100%;
        }
    }
    p{
        text-align: center;
        font-family: 'Montserrat';
        font-weight: 500;
        color: #707070;
    }
    h2{
        font-size: 2em;
        color: black;
        font-family: 'Serpentine ICG', 'Helvetica';
        margin-bottom: 1rem;
        text-align: center;
    }
    
}