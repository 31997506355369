.assign-form {
    .assign-form-button-container {
        margin-top: 0;
        padding-right: 10%;
        Button {
            margin-top: 0;
        }
    }
    .filter-container {
        margin-bottom: 10px;
    }
}