.port-form-container{
    width: 100%;
    border-top: 1px solid #707070;
    .input-container-port {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        .label-container {
            width: 70%;
            display: flex;
            justify-content: left;
        }
    }
}

.record-form-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    .inputs-container {
        display: flex;
        .input-container-form {
            .label-container {
                width: 100%;
                .container-label {
                    font-family: "Montserrat";
                    font-weight: 300;
                    font-size: 1.1vw;
                    color: #434242;
                }
            }
            .value-container-select {
                width: 100%;
                .input-select {
                    width: 100%;
                }
            }
        }
    }
}

.delete-container-button{
    display: flex; 
    justify-content: flex-end;
    img {
        width: 35px;
    }
}
.input-container-port{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 20px 0;
    width: 100%;
    .label-container{
        width: 30%;
        display: flex;
        justify-content: flex-end;
        .container-label{
            font-family: "Montserrat";
            font-weight: 600;
            font-size: 1.1vw;
            color: #0066B3;
        }

    }
    .value-container{
        width: 70%;
        display: flex;
        justify-content: flex-start;
        .input-generic{
            width: 100%;
            padding: 10px;
            border-radius: 12px;
            border: none;
            background-color: rgba(4 , 5, 5 , .2);
            padding-left: 13px;
            font-size: 1.1vw;
        }
        .input-generic.date {
            display: flex;
            flex-direction: row-reverse;
        }
    }

    .styleNone{
        display: none;
    }
    .style{
        display: block;
    }

}