
.optionItemContainer.open {
    width: 100%;
}
.optionItemContainer {
    padding: 2px;
    opacity: .8;
    border-radius: 3px;
    display: flex;
    align-items: center;
    width: fit-content;
    cursor: pointer;
    &:hover {
        background-color: #707070;      
        .sidebarItemIconContainer {
            background-color: #D7091B;
            .iconImg {
                width: 70%;
                filter: grayscale(200%) invert(1) contrast(10);
                color: white;
            }
        }
    }
    .sidebarItemIconContainer {
        background-color: white;
        opacity: 1;
        width: 30px;
        height: 30px;
        margin: 3px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        border-radius: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        &:hover {
            background-color: #D7091B;
            .iconImg {
                width: 70%;
                filter: grayscale(200%) invert(1) contrast(10);
            }
        }
        .iconImg {
            // filter: invert(59%) sepia(82%) saturate(3904%) hue-rotate(141deg) brightness(94%) contrast(98%);
            filter: none;
            width: 70%;
        &:hover {
            // filter: none;
            filter: grayscale(200%) invert(1) contrast(10);
            width: 70%;
        }
        }
    }
    .link {
        color: #fff;
        font-size: 12px;
        font-weight: bold;
        font-family: "Montserrat", sans-serif;
        text-decoration: none;
        margin-left: 10px;
    }
}