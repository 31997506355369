.body-form-generic-type-data {
    overflow-y: scroll;
}
.main-container-generic-type-quotation {
    // .value-container-principal-data.provider {
    //     overflow-y: scroll;
        .quotation-options-container  {
            height: 45vh;
            .body-form-generic-type-data {
                height: 80%;
                overflow-y: scroll;
                // .quotation-form-general-container.extraQuotation {
                //     height: 100%;
                //     overflow-y: scroll;
                // }
                // .value-container-principal-data.provider {
                //     margin-bottom: 0;
                //     height: 100%;
                //     overflow-y: scroll;
                    .input-container.provider.div-input {
                        margin-bottom: 5px;
                    }
                    .instructions {
                        margin-bottom: 10px;
                    }
                }
            }
        }
        .footer-form-generic-type.extraFooter {
            margin-top: 0;
        }
    // }    
// }