@import './../../../styles/mixinsAndVariables';



//---------Login
.content-Login {
    width: 100vw;
    height: 100vh;
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    background-image: url("../../../assets//images/fondo_home.png");

    .subcontainer-login {
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
        width: 50%;
        padding-top: 6vw;

        .img-logo {
            width: 26vw;
            margin-bottom: 6.5vw;
        }

        // .content-form-login {
        //     width: 50%;
        // }

        // .form-logIn {
        //     display: flex;
        //     flex-direction: column;
        //     justify-content: center;
        //     align-items: center;

        // }


        .form-logIn {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;

            .txt-login {
                color: #FFFFFF;
                font-weight: bold;
                font-size: 1.5vw;
                margin-bottom: 5vh;


            }

            .log-in {
                display: flex;
                flex-direction: column;
                width: 50%;

                .link {
                    color: #fff;
                    text-decoration: none;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: .9vw;
                    // font-weight: 200;
                    font-family: "Helvetica";
                    opacity: .6;
                }

                .link:hover {
                    color: #fff;
                    text-decoration: none;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: .9vw;
                    font-weight: 200;
                    font-family: "Helvetica";
                    margin-top: -.5vh;
                    opacity: 1;
                }

                .session {
                    padding: 9px 32px 9px 32px;
                    color: #fff;
                    font-size: 1vw;
                    font-weight: 600;
                    background-color: $buttons-red;
                    border: none;
                    border-radius: 20px 20px 20px 20px;
                    margin-top: 30px;
                    margin-bottom: 20px;
                    text-decoration: none;
                    font-family: 'Helvetica';
                    cursor: pointer;

                    .txt-btn {
                        text-decoration: none;
                        color: $button-neutrals;
                        font-size: 1vw;
                        font-family: "Helvetica";
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-weight: 200;
                    }

                }

                .session:hover {
                    padding: 9px 32px 9px 32px;
                    color: #fff;
                    font-size: 1vw;
                    font-weight: 600;
                    background-color: $button-red-secondary;
                    border: none;
                    border-radius: 20px 20px 20px 20px;
                    margin-top: 26px;
                    margin-bottom: 24px;
                    text-decoration: none;
                    font-family: 'Helvetica';
                    cursor: pointer;

                }


                .email-input {
                    height: 2vw;
                    border-radius: 13px;
                    width: 100%;

                }



                .container-input-component {
                    display: flex;


                    .btn-input-component-icouser:hover {
                        border: .8px solid $button-red-secondary;
                    }


                    .btn-input-component-icouser {
                        width: 100vw;
                        border: none;
                        margin-bottom: 15px;
                        display: flex;
                        align-items: center;
                        border-radius: 20px 20px 20px 20px;
                        background: rgba(112, 112, 112, .5);

                        .subcontainer-img-left {
                            width: 10%;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            img {
                                width: 35px;
                                min-width: 14px;
                                text-align: center;
                                padding-left: 13px;
                            }
                        }


                    }



                    .input-component-login {
                        background: transparent;
                        width: 82%;
                        border: none;
                        padding: 10px;
                        margin-left: 5px;
                        color: $light-background;
                        font-size: 1vw;
                        font-family: "Montserrat";
                        height: 6vh;
                    }

                    .input-component-login:active {
                        outline: 0 none;
                        font-size: 1vw;
                        font-family: "Montserrat";
                        color: $light-background;
                        background: transparent;

                    }

                    // .input-component-login:{
                    //     outline: 0 none;
                    //     font-size: 1vw;
                    //     font-family: "Montserrat";
                    //     color: $light-background;
                    //     background: transparent;

                    // }

                    .input-component-login:focus {
                        outline: 0 none;
                        font-size: 1vw;
                        font-family: "Montserrat";
                        color: $light-background;
                    }

                    .input-component-login::placeholder {
                        color: $light-background;
                        font-size: 1vw;
                        font-family: "Montserrat";
                    }

                }
            }
        }
    }
}

//---------------------------Recuperar Pass----------------------------------------------
.form-recovery-pass {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    .txt-login {
        color: #FFFFFF;
        font-weight: bold;
        font-size: 1.5vw;
        margin-bottom: 5vh;


    }

    .log-in {
        display: flex;
        flex-direction: column;
        width: 50%;

        .link {
            color: #fff;
            text-decoration: none;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: .9vw;
            // font-weight: 200;
            font-family: "Helvetica";
            opacity: .6;
        }

        .link:hover {
            color: #fff;
            text-decoration: none;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: .9vw;
            font-weight: 200;
            font-family: "Helvetica";
            margin-top: -.5vh;
            opacity: 1;
        }

        .session {
            padding: 9px 32px 9px 32px;
            color: #fff;
            font-size: 1vw;
            font-weight: 600;
            background-color: $buttons-red;
            border: none;
            border-radius: 20px 20px 20px 20px;
            margin-top: 50px;
            margin-bottom: 20px;
            text-decoration: none;
            font-family: 'Helvetica';
            cursor: pointer;

            .txt-btn {
                text-decoration: none;
                color: $button-neutrals;
                font-size: 1vw;
                font-family: "Helvetica";
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: 200;
            }


        }

        .session:hover {
            padding: 9px 32px 9px 32px;
            color: #fff;
            font-size: 1vw;
            font-weight: 600;
            background-color: $button-red-secondary;
            border: none;
            border-radius: 20px 20px 20px 20px;
            margin-top: 45px;
            margin-bottom: 24px;
            text-decoration: none;
            font-family: 'Helvetica';
            cursor: pointer;

        }


        .email-input {
            height: 2vw;
            border-radius: 13px;
            width: 100%;

        }



        .container-input-component {
            display: flex;


            .btn-input-component-icouser:hover {
                border: .8px solid $button-red-secondary;
            }


            .btn-input-component-icouser {
                width: 100vw;
                border: none;
                margin-bottom: 15px;
                display: flex;
                align-items: center;
                border-radius: 20px 20px 20px 20px;
                background: rgba(112, 112, 112, .5);

                .subcontainer-img-left {
                    width: 10%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    img {
                        width: 35px;
                        min-width: 14px;
                        text-align: center;
                        padding-left: 13px;
                    }
                }
            }



            .input-component-login {
                background: transparent;
                width: 82%;
                border: none;
                padding: 10px;
                margin-left: 5px;
                color: $light-background;
                font-size: 1vw;
                font-family: "Montserrat";
                height: 6vh;
            }

            .input-component-login:active {
                outline: 0 none;
                font-size: 1vw;
                font-family: "Montserrat";
                color: $light-background;
                background: transparent;

            }


            .input-component-login:focus {
                outline: 0 none;
                font-size: 1vw;
                font-family: "Montserrat";
                color: $light-background;
            }

            .input-component-login::placeholder {
                color: $light-background;
                font-size: 1vw;
                font-family: "Montserrat";
            }
        }
    }
}







/////------------------------- Restablecer Passs
.form-restore {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    .txt-login {
        color: #FFFFFF;
        font-weight: bold;
        font-size: 1.5vw;
        margin-bottom: 5vh;


    }

    .log-in {
        display: flex;
        flex-direction: column;
        width: 50%;

        .link {
            color: #fff;
            text-decoration: none;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: .9vw;
            // font-weight: 200;
            font-family: "Helvetica";
            opacity: .6;
        }

        .link:hover {
            color: #fff;
            text-decoration: none;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: .9vw;
            font-weight: 200;
            font-family: "Helvetica";
            margin-top: -.5vh;
            opacity: 1;
        }

        .session {
            padding: 9px 32px 9px 32px;
            color: #fff;
            font-size: 1vw;
            font-weight: 600;
            background-color: #D7091B;
            border: none;
            border-radius: 20px 20px 20px 20px;
            margin-top: 31px;
            margin-bottom: 20px;
            text-decoration: none;
            font-family: 'Helvetica';
            cursor: pointer;

            .txt-btn {
                text-decoration: none;
                color: $button-neutrals;
                font-size: 1vw;
                font-family: "Helvetica";
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: 200;
            }

        }

        .session:hover {
            padding: 9px 32px 9px 32px;
            color: #fff;
            font-size: 1vw;
            font-weight: 600;
            background-color: $button-red-secondary;
            border: none;
            border-radius: 20px 20px 20px 20px;
            margin-top: 27px;
            margin-bottom: 20px;
            text-decoration: none;
            font-family: 'Helvetica';
            cursor: pointer;

        }


        .email-input {
            height: 2vw;
            border-radius: 13px;
            width: 100%;

        }



        .container-input-component {
            display: flex;


            .btn-input-component-icouser:hover {
                border: .8px solid $button-red-secondary;
            }


            .btn-input-component-icouser {
                width: 100vw;
                border: none;
                margin-bottom: 15px;
                display: flex;
                align-items: center;
                border-radius: 20px 20px 20px 20px;
                background: rgba(112, 112, 112, 0.5);

                .content-img-viwe-pass {
                    background: $buttons-red;
                    width: 10%;
                    height: 6vh;
                    border-top-right-radius: 15px;
                    border-bottom-right-radius: 15px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                .img-view-pass {
                    width: 23px;
                    display: flex;
                }
            }

            .subcontainer-img-left {
                width: 10%;
                display: flex;
                justify-content: center;
                align-items: center;

                .img-left {
                    width: 35px;
                    min-width: 14px;
                    text-align: center;
                    padding-left: 13px;
                }

            }
        }



        .input-component-login {
            background: transparent;
            width: 80%;
            border: none;
            padding: 10px;
            margin-left: 5px;
            color: $light-background;
            font-size: 1vw;
            font-family: "Montserrat";
            height: 6vh;
        }

        .input-component-login:active {
            outline: 0 none;
            font-size: 1vw;
            font-family: "Montserrat";
            color: $light-background;
            background: transparent;

        }


        .input-component-login:focus {
            outline: 0 none;
            font-size: 1vw;
            font-family: "Montserrat";
            color: $light-background;
        }

        .input-component-login::placeholder {
            color: $light-background;
            font-size: 1vw;
            font-family: "Montserrat";
        }
    }
}








//---------------------------------------------------------------------------------------------------------------------------
// .LogInMain {
//     @include Reset;
//     @include mainSectionKoala;
// }

// .buttonsContainer {
//     @include buttons;
// }

// .LogInMain section {
//     .container-image-koala {
//         @include containerImageKoala;
//     }

//     .form-container {
//         @include formContainer(18%);

//         .buttons-log-sign {
//             align-self: flex-end;
//         }

//         div .log {
//             @include buttonPillLoginKoala;
//             @include buttonActive;
//         }

//         div .sign {
//             @include buttonPillSignupKoala;
//             @include buttonInactive;
//         }

//         .form-logIn {
//             @include formBasicKoala;

//             .log-in {
//                 @include formDiv(35px, 20px);

//                 label {
//                     @include labelsKoala;
//                 }

//                 input {
//                     @include inputsKoala;
//                 }

//                 .link {
//                     margin-top: 15px;
//                     margin-left: 0;
//                     padding-left: 0px;
//                     color: #000;
//                     font-size: 16px;
//                     text-align: left;
//                     display: inline-block;
//                 }

//                 Button {
//                     @include buttonFormKoala;
//                 }
//             }
//         }
//     }
// }

// footer {
//     @include footerStyle();
// }

// .form-sign-up {
//     width: 100%;
// }

// .form-title-container {
//     font-size: 12px;
//     font-weight: 700;
//     display: flex;
//     justify-content: right;
//     margin-bottom: 15px;
// }

// .form-title {
//     color: $buttons-red;
// }