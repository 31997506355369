
.input-container-data.record-data {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 5px;
    .input-column-container {
        width: 100%;
        display: grid;
        grid-template-columns: 50% 50%;
    }
}

.input-container-data.record-data {
    margin: 0;
    padding: 5px;
    h3 {
        margin: 10px 0 0 10px;
        font-size: .8rem;
    }
}

.input-row-container.records-payment-data {
    align-items: flex-start;
}

.input-container-data, .body-form-generic-type-data {
    .input-row-container {
        width: 100%;
        display: grid;
        grid-template-columns: 50% 50%;
        .title-container {
            margin-left: 10px;
        }
    }
    .input-column-container.records, .input-row-container, .input-column-container.delivery {
        .input-container{
            display: flex;
            flex-direction: column;
            width: 100%;
            padding: 10px;
            margin: 0;
            .label-container {
                width: 100%;
                display: flex;
                justify-content: left;
                .container-label {
                    font-family: "Montserrat";
                    font-weight: 400;
                    font-size: 1.1vw;
                    color: #0066B3;
                }
            }
            .value-container{
                width: 100%;
                .input-generic {
                    width: 100%;
                }
                .input-generic.date {
                    display: flex;
                    flex-direction: row-reverse;
                }
            }
        }
    }
}


.card-container-generic-type.records-data {
    width: 100%;
    height: 100%;
    margin-bottom: 40px;
}

.stepper-container.records{
    width: 55%;
    margin: 1em 0 3em 0;
    height: 2em;
}

.card-container-stepper.records {
    width: 80%;
}

.body-form-generic-type.records.checkboxes {
    margin: 0 0;
    padding: 25px;
    align-items: center;
}
.body-form-generic-type.records {
    margin: 0;
    .input-container-data.records {
        width: 100%;
        .input-container {
            padding: 0 10px;
        }
    }
}

.input-container-data.records.checkboxes {
    display: flex;
    flex-direction: column;
    margin: 10px;
    width: 98%;
    .input-row-container {
        justify-content: space-between;
        display: flex;
        width: 100%;
        .checkbox-records-container {
            display: flex;
            align-items: center;
            .checkbox-records {
                margin-left: 15px;
                width: 15px;
                height: 15px;
                border: 2px solid rgb(167, 167, 167);
                border-radius: 3px;
            }
        }
    }
}

.body-form-generic-type-datarecord-data.records.checkboxes {
    display: flex;
    justify-content: center;
    overflow-y: scroll;
    .input-container-data.records.checkboxes {
        .input-row-container {
            justify-content: space-between;
            display: flex;
            width: 100%;
    }
}
    .input-container-data.records {
        width: 100%;
        display: flex;
        flex-direction: row;
        margin-top: 0;
        margin-bottom: 0;
        .input-column-container {
            width: 50%;
            .delay-reason {
                p {
                    margin-left: 5px;
                    margin-bottom: 10px;
                    font-size: 1rem;
                }
            }
            .input-container {
                padding: 10px;
                display: flex;
                flex-direction: column;
                .container-label {
                    font-size: 1rem;
                }
                .value-container.arrival {
                    display: flex;
                    flex-direction: column;
                    p {
                        font-size: .6rem;
                        margin-left: 5px;
                        margin-top: 5px;
                        margin: 5px 5px 0 5px;
                    }
                }
                .value-container {
                    .input-generic {
                        background-color: rgba(4 , 5, 5 , .2);
                        border-radius: 25px;
                        height: 2.6rem;
                    }
                    .input-time.records {
                        width: 100%;
                        border-radius: 25px;
                        height: 2.6rem;
                    }
                    .input-select {
                        border-radius: 25px;
                        height: 2.6rem;
                    }
                }
            }
        }
    }
}

.footer-form-generic-type.records {
    display: flex;
    justify-content: space-evenly;
}

.body-form-generic-type-data.record-data {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    .credit-header-container {
        display: flex;
    }
}

.input-container-data.records-arrival-dates {
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
}
.value-container.arrival {
    display: flex;
    flex-direction: column;
    width: 100%;
    p {
        font-size: .6rem;
        margin-left: 5px;
        margin-top: 5px;
    }
}

.body-form-generic-type.records.payment {
    height: 30%;
    overflow-y: scroll;
}

.input-column-container.delivery {
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
}


.MuiCheckbox-colorSecondary.Mui-checked {
    background-color: #0066B3 !important;
}
