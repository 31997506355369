:root {
    .MuiTable-root {
        overflow-x: scroll;
    }
    .MuiTableCell-head {
        font-weight: 500 !important;
        background-color: #707070;
    }
    .MuiTableCell-head:first-child {
        border-top-left-radius: 10px;
        // border-bottom-left-radius: 10px;
    }
    .MuiTableCell-head:last-child {
        border-top-right-radius: 10px;
        // border-bottom-right-radius: 10px;
    }
    .MuiTableCell-root {
        padding: 0px;
        font-family: "Poppins", "Helvetica", "Arial", sans-serif;
    }
    .MuiTableRow-head{
        margin: 10px !important;
    }
    .MuiTableRow-root{
        margin: 10px !important;
    }
    
    .MuiIconButton-root{
        .MuiIconButton-label {
            width: 100%;
            display: flex;
            align-items: inherit;
            justify-content: inherit;
            background-color: #D7091B;
            border-radius: 5px;
            .MuiSvgIcon-root {
                fill: white;
                width: 1em;
                height: 1em;
                display: inline-block;
                font-size: 1.5rem;
                transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                flex-shrink: 0;
                user-select: none;
            }
        }
    }
    .MuiRadio-root{
        .MuiIconButton-label {
            width: 100%;
            display: flex;
            align-items: inherit;
            justify-content: inherit;
            background-color: transparent;
            border-radius: 5px;
            .MuiSvgIcon-root {
                fill: white;
                width: 1em;
                height: 1em;
                display: inline-block;
                font-size: 1.5rem;
                transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                flex-shrink: 0;
                user-select: none;
            }
        }
    }
    
}