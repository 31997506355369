@import '../../../../styles/mixinsAndVariables';

@mixin centerItems($direction: row, $justify: center) {
    display: flex;
    flex-direction: $direction;
    justify-content: $justify;
    align-items: center;
}

header nav.header {
    height: 90px;
    justify-content: flex-start;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-position: right 55px;
    position: fixed;
    right: 0;
    width: calc(100vw - 260px);
    transition: all .2s ease-in-out;
    z-index: 1000;
    box-shadow: 2px 1px 2px #707070;
    background-color: black;
    &.container-fluid {
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
    }

    .headerFirstContainer {
        width: 12%;
        height: 100%;
    }
    }

    // @media screen and (max-width: 1340px) {
    //     .headerMiddleContainer {
    //         width: 250px;
    //     }
    // }

    // @media screen and (max-width: 840px) {
    //     .sectionTitle {
    //         display: none;
    //     }
    // }

    .headerSecondContainer {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        padding-left: 20px;
        align-items: center;
        height: 100%;
        width: 60%;
        opacity: 1;

        .backButton {
            width: 2rem;
            height: 2rem;
        }
        .txt-header{
            font-family: 'Montserrat';
            font-size: 32px;
            color: #FFFFFF;
            margin-left: 1rem;
            font-weight: 700;
        }
        .button-default {
            font-size: 1.7vw;
            font-weight: 700;
            height: 2rem;
            padding: 5px 20px;
            img {
                width: 1.4vw;
                margin-right: 5px;
            }
        }
    }
    .headerThirdContainer {
        height: 100%;
        width: 18%;
        @include centerItems();
        opacity: 1;
        .button-default {
            font-size: 1.7vw;
            font-weight: 700;
            height: 2rem;
            padding: 5px 20px;
            img {
                width: 1.4vw;
                margin-right: 5px;
            }
        }
    }
    .headerFourthContainer {
        height: 100%;
        width: 22%;
    }
    .headerFifthContainer {
        height: 100%;
        width: 22%;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 1;
        .adminAccount {
            display: flex;
            width: auto;
            padding: 2px;
            justify-content: center;
            align-items: center;
            height: fit-content;
            border: solid 1px rgb(76, 75, 75);
            border-radius: 25px;
            color: #fff;
            background-color: #707070;
            margin-right: 30px;
            .adminAccount__p {
                margin: 5px;
                margin-left: 10px;
                margin-right: 10px;
                font-family: "Poppins", sans-serif;
                background-color: #707070;
                color: #fff;
                border-radius: 25px;
                width: 100%;
                padding: 2px;
                p {
                    font-family: "Poppins", sans-serif;
                    color: #fff;
                }
            }
            .adminAccount__p:focus {
                color: #707070;;
            }
            .adminAccount__img {
                margin: 5px;
                margin-left: 10px;
            }
        }
    }
    header nav.headerClose {
        width: calc(100vw - 150px);
        transition: all .2s ease-in-out;
    }
.header-toggle-btn {
    visibility: hidden;
    z-index: 99;
}

.backButton {
    margin-top: 10px;
    margin-left: 5px;
    // width: 60%;
    min-width: 25px;
    cursor: pointer;
    &:hover {
        filter: transparent linear-gradient(90deg, #B7F091A6 0%, #6AD2A1A6 100%) 0% 0% no-repeat padding-box;
    }
}
