@import '../../../../styles/mixinsAndVariables';
// nav {
//     width: 250px;
// }

aside nav.sidebar {
    position: fixed;
    bottom: 0;
    background-color: #000;
    width: 260px;
    height: 100vh;
    left: 0;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    z-index: 1001;
    &.navMin {
        width: 160px !important;
        background-color: #000;
        align-items: center;
    }

    &.navMin .sidebar-item {
        -webkit-transform: translate3d(-200px, 0, 0);
        transform: translate3d(-200px, 0, 0);
    }
}

.menu {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 8%;
    left: 6%;
    z-index: 99;
}

.sidebar {
    width: 100%;
    height: calc(96vh-180px);
    background-color: rgb(10, 9, 9);
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    transition: left 0.4s;
    // padding-left: 2vw;
    // padding-right: 3vw;
}
.header-logo{
    border-bottom: 2px solid #707070;
    width: 84%;
    height: 90px;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-end;
    padding-bottom: 15px;
    margin-right: 10px;
    .logo-container{
        width: 80%;
        display: flex;
        justify-content: flex-start;
        align-items: baseline;
        margin-bottom: 5px;
        img{
            width: 80%;
            min-width: 5rem;
        }
    }
    .toogle-button-container{
        width: 2vw;
        margin-right: .5vw;
        padding-bottom: 5px;
        cursor: pointer;
        img {
            width: 2vw;
        }
    }
}
.panel {
    left: 0;
    width: 100%;
    padding-left: 5px;
    padding-right: 2px;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 30px;
}
.activePanel {
    left: 0;
    width: 100%;
    padding-left: 25px;
    padding-right: 2px;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 30px;
}
.activeText {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #fff;
    background-color: rgb(56, 55, 55);
    padding: 5px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    width: 100%;
    height: 100%;
    text-align: left;
    padding-left: 10px;
    opacity: 1;
    &:hover {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
    }

}

