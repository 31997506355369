.delay-modal-container {
    width: 600px;
    height: 450px;
    padding: 15px;
    padding-bottom: 5px;
    display: grid;
    grid-template-rows: 30% 55% 15%;
    .delay-title-container {
        display: grid;
        grid-template-rows: 50% 25% 25%;
        font-family: "Montserrat";
        padding-bottom: 8px;
        .input-number-days-container {
            display: flex;
            width: 100%;
            align-items: center;
            .days-text {
                margin-left: 10px;
                font-family: "Montserrat";
                font-weight: 500;
            }
            .days-delay-input {
                width: 30%;
                padding: 10px;
                border-radius: 15px;
                border: none;
                background-color: rgba(4, 5, 5, 0.2);
                padding-left: 13px;
                font-size: 1.1vw;
            }
        }
        h3 {
            font-size: 1.3rem;
        }
        h4 {
            font-size: .9rem;
            font-weight: 500;
        }
    }
    .delay-reason {
        margin-top: 20px;
        color: #878787;
        font-weight: 200;
        font-size: .6rem;
        .reasons-delay-input {
            width: 95%;
            height: 140px;
            border: none;
            background-color: rgba(4, 5, 5, 0.2);
            border-radius: 10px;
            margin: 10px 20px 20px 0px;        
            padding: 10px;
            text-align: start;
        }
    }
    .footer-form-generic-type {
        button {
            margin-top: 20px;
            margin-bottom: 4px;
        }
    }
}