
.arrow.rotate {
    transform: rotate(180deg);
}
.OptionsSelectorContainer {
    height: 100%;
    width: 100%;
}
.profileLogoutButtonContainer {
.buttonMenuContainer {
    width: fit-content;
    padding: 5px;
    background-color: #D7091B;
    .menuItem {
        width: 10px;
    }
}
}
    .userIconContainer {
        width: 30px;
        height: 30px;
        border-radius: 16px;
        background-color: transparent;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 15px;
        position: absolute;
        top: 20px;
        right: 16.4vw;
        img {
            width: 30px;
        }
    }
    .profileLogoutButtonContainer {
        width: 12vw;
        // min-width: 9rem;
        // max-width: 12rem;
        position: absolute;
        top: 35px;
        right: 4.5vw;
        .buttonSelectorContainer {
            display: flex;
            width: 100%;
            padding: 5px 5px;
            justify-content: space-evenly;
            align-items: center;
            height: 2vw;
            min-height: 25px;
            border-radius: 20px;
            background-color: rgba(112, 112, 112, .5);
            margin-right: 10px;
            margin-bottom: 5px;
            cursor: pointer;
            p {
                font-size: .8vw;
                color: #fff;
                font-weight: 500;
                font-family: "Poppins", sans-serif;
            }
        &:hover{
            border: .5px solid #D7091B;
        }
            Link {
                font-family: "Poppins", sans-serif;
                font-size: 14px;
                opacity: 1;
                margin: 0 10px;
            }
            .arrow {
                width: 12px;
                margin-right: 5px;
            }
        }

        .deployedOptionsContainer {
            display: flex;
            flex-direction: column;
            width: 100%;
            padding: 1px;
            padding-bottom: 15px;
            padding-top: 13px;
            justify-content: center;
            align-items: center;
            height: fit-content;
            border-radius: 20px;
            color: #fff;
            background-color: rgba(112, 112, 112, .5);
            font-family: "Poppins", sans-serif;
            margin-bottom: 5px;
            margin-top: 5px;
            cursor: pointer;
            position: absolute;
            top: 40px;
            .optionStyleContainer {
                padding: 7px 0;
                width: 98%;
                border-bottom: 1px solid #fff;
                text-align: center;
                &:hover {
                    background-color: #D7091B;
                    opacity: 1;
                    color: #fff;
                }
                .optionStyleText {
                font-family: "Poppins", sans-serif;
                font-weight: 500;
                opacity: 1;
                text-decoration: none;
                color: #fff;
                }
            }
        }
    }
