.container-label {
    font-family: "Montserrat";
    font-weight: 600;
    font-size: .7rem;
    color: #0066B3;
}

.record-register-documents {
    overflow-y: scroll;
    width: 100%;
    height: 100%;
    .body-form-generic-type-docs {
        height: 100%;
    }
}
.records-documentation-container {
    position: absolute;
    top: 110px;
}
.records-documentation-container .main-container-generic-type.records .body-form-generic-type-docs {
    height: 95%;
}

.body-form-generic-type-register {
    height: 80%;
    width: 100%;
    .record-tracking-header, .record-register-header {
        width: 100%;
        padding: 10px 0 5px 0;
        h3 {
            font-size: 1.2rem;
        }
    }
    .record-register-header {
        margin: 0 20px;
    }

    .record-register-general-data {
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-rows: 18% 28% 18% 18% 18%;
        overflow-y: scroll;
    }
    .record-register-tracking, .record-register-rute {
        overflow-y: scroll;
        width: 100%;
        height: 82%;
    }
    .record-register-rute {
        margin: 10px 20px;
        .places-container {
            padding: 5px 20px 5px 0;
            display: grid;
            grid-template-columns: 50% 50%;
            font-family: 'Montserrat';
            width: 100%;
        }
    }
    input, .input-time {
        background-color: rgba(4, 5, 5, 0.1);
    }
    .input-area-title-container {
        margin-top: 20px;
        width: 100%;
        max-height: 30px;
    }

    // .input-container {
    //     width: 100%;
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //     flex-direction: column;
    //     // gap: 10px;
    //     margin: 20px 0;
    // }
    .register-inputs-container {
        display: flex;
        justify-content: space-evenly;
        .input-container-record.delay-date {
            margin-top: 10px;
            .label-container {
                padding-left: 10px;
            }
            .value-container {
                padding: 10px;
                margin: 0;
                width: 100%;
                .input-time.records {
                    margin: 0;
                    width: 100%;
                }
            }
        }
        .input-container-record {
            width: 48%;
            .container-label {
                font-family: "Montserrat";
                font-weight: 600;
                font-size: .7rem;
                color: #0066B3;
            }
            .value-container {
                .input-generic {
                    background-color: rgba(4, 5, 5, 0.1);
                }
            }
        }
        .delay-title-container {
            width: 48%;
            margin-top: 10px;
            .label-container {
                .container-label {
                    font-family: "Montserrat";
                    font-weight: 600;
                    font-size: .7rem;
                    color: #0066B3;
                    margin-left: 7px;
                }
            }
        }
        .input-number-days-container {
            display: flex;
            width: 100%;
            align-items: center;
            padding: 10px;
            .days-text {
                margin-left: 10px;
                font-family: "Montserrat";
                font-weight: 700;
                font-size: .7rem;
            }
            .days-delay-input {
                width: 70%;
                padding: 10px;
                border-radius: 15px;
                border: none;
                background-color: rgba(4, 5, 5, 0.1);
                padding-left: 13px;
                font-size: .7rem;
            }
        }
        .delay-reason {
            color: #0066B3;
            font-family: "Montserrat";
            font-weight: 600;
            font-size: .7rem;
            width: 48%;
            padding: 10px;
            .reasons-delay-input {
                width: 100%;
                height: 80px;
                border: none;
                background-color: rgba(4, 5, 5, 0.1);
                border-radius: 10px;
                margin: 9px 20px 20px 0px;        
                text-align: start;
            }
        }
    }
}
.footer-form-generic-type.record-register {
    display: flex;
    justify-content: space-evenly;
    padding: 0 50px;
    button {
        margin-top: 13px;
    }
}

.text-area-container {
    padding-left: 20px;
    max-height: 60px;
    p {
        margin-right: 5px;
        font-size: .7rem;
    }
}

.text-area-container.checkbox {
    display: flex;
    align-items: center;
}
.text-area-container.title {
    padding-top: 20px;
}