.status-data-container {
    width: 100%;
    height: 100%;
    padding-top: 25px;
    padding-bottom: 25px;
    overflow-y: scroll;
    .checkpoint-container {
        display: flex;
        justify-content: left;
        align-items: center;
        cursor: pointer;
        .checkpoint {
            width: 13px;
            height: 13px;
            background-color: #0966d7;
            border-radius: 8px;
            margin-right: 20px;
        }
        .place {
            color: #000;
            font-weight: 500;
            font-family: 'Montserrat';
            font-size: 14px;
        }
    }
    .line-between-checkpoints-container {
        display: flex;
        align-items: center;
        .line-between-checkpoints {
            border-right: 1.5px solid #0966d7;
            width: 1px;
            height: 25px;
            margin-left: 5.5px;
            margin-right: 25px;
        }
        .line-between-checkpoints.none {
            border-right: 1.5px solid transparent;
        }
        .status-container {
            display: flex;
            font-size: .8vw;
            color: #878787;
            font-family: 'Montserrat';
            .space {
                margin: 0 4px;
            }
        }
    }
}