.record-register-general-data-one {
    margin-top: 20px;
    height: 95%;
    width: 100%;
    overflow-y: scroll;
    font-family: "Montserrat";

    .container-label {
        font-family: "Montserrat";
        font-weight: 600;
        font-size: .8rem;
        color: #0066B3;
        display: flex;
        justify-content: left;
        width: 100%;

    }

    .container-colums {
        display: flex;
        flex-direction: row;
        gap: 5%;
        padding-top: 2%;

        .container-row {
            width: 33.3%;

            .container-label-sub {
                font-family: "Montserrat";
                font-weight: 500;
                font-size: .8rem;
                color: #5f6f7c;
                display: flex;
                justify-content: left;
                width: 100%;

            }

            .input-time {
                display: flex;
                flex-direction: row-reverse;
                border-radius: 15px;
                border: none;
                background-color: rgba(4, 5, 5, 0.2);
                padding: 8px ;
                font-size: 1.1vw;
                width: 100%;
                color: #707070;
            }
        }

        .container-row-sub {
            width: 30%;


            .container-label-sub {
                font-family: "Montserrat";
                font-weight: 500;
                font-size: .8rem;
                color: #5f6f7c;
                display: flex;
                justify-content: left;
                width: 100%;

            }

            .input-time {
                display: flex;
                flex-direction: row-reverse;
                border-radius: 15px;
                border: none;
                background-color: rgba(4, 5, 5, 0.2);
                padding: 8px;
                font-size: 1.1vw;
                width: 100%;
                color: #707070;
            }

        }

    }

    .container-label-one {
        font-family: "Montserrat";
        font-weight: 600;
        font-size: .8rem;
        color: #0066B3;
        display: flex;
        justify-content: left;
        width: 100%;
        padding-top: 2%;
        padding-top: 3%;

    }

}

// .record-register-general-data-one {
//     margin-top: 20px;
//     height: 95%;
//     width: 100%;
//     display: grid;
//     grid-template-rows: 18% 28% 18% 18% 18%;
//     overflow-y: scroll;
//     .register-inputs-container {
//         display: grid;
//         grid-template-columns: 47% 47%;
//         margin: 0;
//         height: fit-content;
//         .input-container {
//             .input-time.records {
//                 width: 100%;
//             }
//         }
//         .delay-reason {
//             width: 100%;
//             display: flex;
//             flex-direction: column;
//             justify-content: flex-start;
//         }
//         .delay-title-container {
//             width: 100%;
//             margin-top: 15px;
//             .input-number-days-container {
//                 padding-left: 0;
//             }
//         }
//         .input-container-record {
//             .value-container {
//                 margin-top: 9px;
//             }
//         }
//         .input-container-record, .input-container {
//             display: flex;
//             flex-direction: column;
//             width: 100%;
//             padding: 0;
//             .container-label {
//                 font-family: "Montserrat";
//                 font-weight: 600;
//                 font-size: .7rem;
//                 color: #0066B3;
//                 display: flex;
//                 justify-content: left;
//                 width: 100%;

//             }
//             .label-container, .value-container {
//                 width: 100%;
//             }
//             .input-generic {
//                 width: 100%;
//             }
//         }
//     }
// }