.input-time.operative {
    width: 100%;
}
.form-admin-container.operative {
    padding-top: 10px;
    height: auto !important;
}
.main-container-generic-type-quotation.operative {
    height: 100%;
    .quotation-options-container {
        height: 65vh;
    }
    .body-form-generic-type-data {
        // height: 75vh;
        height: 84%;
    }
}