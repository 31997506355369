@import './../../styles/stylesSteppers.scss';

.value-container-principal-data.provider {
    width: 95%;
}

.input-files {
    display: none;
}
.input-container.provider {
    margin-top: 20px;
    margin-bottom: 0;
    .value-container .input-generic {
        border-radius: 16px;
    }
    .textArea{
        font-family: "Roboto";
        font-size: 1.1vw;
        font-weight: 400;
    }
}


.input-container.provider.div-input {
    display: flex;
    flex-direction: column;
    .input-label-container {
        display: flex;
        flex-direction: row;
        width: 100%;
        .value-container.div-input {
            margin: 0;
            padding-left: 5px;
            .input-generic.div-input {
                display: flex;
                @include input;
                width: 100%;
                padding: 2px 10px;
                border-radius: 16px;
                border: none;
                background-color: rgba(4, 5, 5, 0.2);
                padding-left: 13px;
                font-size: 1.1vw;
                justify-content: space-between;
                align-items: center;
                color: #727375;
                &:hover {
                    border: 2px solid #0066B3;
                }
                .icon-container {
                    @include input;
                    background-color: #0066B3;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 20%;
                    height: 80%;
                    border-radius: 16px;
                    img {
                        width: 30%;
                    }
                }
            }
        }
        label {
            margin-right: 2px;
        }
    }
}


.instructions {
    width: 90%;
    text-align: center;
    margin-left: 4vw;
    font-family: "Montserrat";
    font-weight: 300;
    font-size: .8vw;
}
