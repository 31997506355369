
    .capture-modal-container {
        width: 600px;
        height: 350px;
        padding: 15px;
        display: grid;
        grid-template-rows: 75% 25%;
        .capture-title-container {
            .inputs-container {
                display: grid;
                grid-template-columns: 50% 50%;
                margin-top: 20px;
                .input-container-record{
                    display: flex;
                    flex-direction: column;
                    .label-container {
                        width: 90%;
                        justify-content: left;
                        margin-bottom: 10px;
                        .container-label {
                            color: rgb(77, 77, 77);
                            font-size: 1vw;
                            font-weight: 500;
                        }
                    }
                    .value-container {
                        width: 90%;
                    }
                }
            }
            .capture-date-action {
                display: grid;
                grid-template-columns: 50% 50%;
                margin-top: 20px;
                .input-container-record {
                    display: flex;
                    flex-direction: column;
                    .label-container {
                        width: 90%;
                        margin-bottom: 10px;
                        justify-content: left;
                        .container-label {
                            color: rgb(73, 73, 73);
                            font-size: 1.2vw;
                        }
                    }
                    .value-container {
                        width: 90%;
                    }
                }
            }
            .footer-form-generic-type {
                .button-next, button {
                    margin-top: 20px;
                    margin-bottom: 5px;
                }
            }
        }
    }