/* Style the list */
ul.breadcrumb {
    /* padding: 10px 16px; */
    list-style: none;
    
}
  
  /* Display list items side by side */
  ul.breadcrumb li {
    display: inline;
    font-size: 20px;
  }
  
  /* Add a slash symbol (/) before/behind each list item */
  ul.breadcrumb li+li:before {
    padding: 8px;
    color: white;
    content: "/\00a0";
  }
  
  /* Add a color to all links inside the list */
  ul.breadcrumb li a {
    color: white;
    text-decoration: underline;
  }
  
  /* Add a color on mouse-over */
  ul.breadcrumb li a:hover {
    color: white;
    text-decoration: underline;
  }

ul.breadcrumb li span {
    color: white;
}