@import './../components/atoms/Button.scss';


section.ClientRecordAccountSection {
    height: auto !important;
    width: 100%;
    text-transform: uppercase;

    .ClientRecordAccountSection__container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .ClientRecordAccountSection__headerContainer {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0;
            flex-direction: column;

            .HomeTableSection__containerFilters {
                width: 100%;
                display: flex;
                justify-content: flex-end;
            }

            .HomeTableSection__containerButtons {
                display: flex;
                justify-content: flex-start;
                width: 100%;

                .linkClient {
                    color: #fff;
                    text-decoration: none;
                }

                .containerButtons__clientButton {
                    margin: 5px 10px;
                    border: 1px solid #bf4737;

                    &:hover {
                        @include buttonHover;
                    }

                }
            }
        }

        .ClientRecordAccountSection__tableContainer {
            width: 100%;
            height: 100%;
            /*padding: 30px;*/
            display: flex;
            flex-direction: column;
            align-items: center;
            background-color: #fff;
            border-radius: 15px;
            box-shadow: 2px 2px 2px 2px #d8d8d8;
            margin-top: 2em;
            overflow-x: scroll;
            grid-auto-flow: column;


            .container-table-grid {
                width: 100%;
                height: 100%;
                overflow: auto;
                scrollbar-width: thin;
                /* Ancho del scroll */
                scrollbar-color: #888888 #f0f0f0;
                /* Color del scroll */
            }
        }
    }
}


.table {
    align-items: center;
    justify-content: center;
}