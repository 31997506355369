.main-container-operative {
    height: 95% !important;
    padding: 30px;
}

.main-container.profile-container {
    margin: 10px 0 25px 0;
}

.main-container-profile{
    height: 84% !important;
}
.form-admin-container{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 80%;
    height: 100%;
    color: #707070;

    .form-admin-profile-cards{
        background-color: white;
        height: 100%;
        border-radius: 8px;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
        
    }
    .form-admin-profile-left{
        width: 35%;
        position: relative;
        padding: 20px;
        .picture-container{
            display: flex;
            justify-content: center;
            position: relative;
            margin-top: 2em;
            .preContainer{
                position: relative;
            }
                .img-profile{
                    width: 9rem;
                    height: 9rem;
                    overflow: hidden;
                    margin: 2em;
                    position: relative;
                    border-radius: 50%;
                }
                .img-rewards{
                    width: 13rem;
                    height: 12rem;
                    overflow: hidden;
                    margin: 2em;
                    margin-top: 0;
                    position: relative;
                    border-radius: 5px;
                }
                
        }
        .bottom-text{
            position: absolute;
            bottom: 0;
            margin-bottom: 1em;
            p{
                font-family: "Montserrat";
            }
        }
        .text-container{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .container-input{
                margin-top: 3em;
                width: 60%;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #D7091B;
                padding: 10px;
                border-radius: 20px;
                color: white;
                .file{
                    display: none;
                    cursor: pointer;
                }
            }
            .disable{
                background-color: #707070;
            }
            p{
                color: #0066B3;
                font-family: "Montserrat";
                text-transform: capitalize;
            }
        }
    }
    .form-admin-profile-right{
        width: 65%;
        padding: 15px;
        .header-form{
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 2.5px solid #c1c1c1;
            padding-bottom: 15px;
            h2{

            }
            .edit-profil-button{
                width: 35px;
                height: 35px;
                border-radius: 5px;
                background-color: #D7091B;
                display: flex;
                justify-content: center;
                align-items: center;
                border: none;
                :hover{
                    background-color: #AA0413;
                    border: none;
                }
                img{
                    width: 27px;
                }
            }
        }
        .body-form{
            overflow: auto;
            height: 80%;
            .input-container{
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 10px;
                margin: 20px 0;
                .label-container{
                    width: 30%;
                    display: flex;
                    justify-content: flex-end;
                    .container-label{
                        font-family: "Montserrat";
                        font-weight: 600;
                        font-size: 1.1vw;
                        color: #0066B3;
                    }

                }
                .value-container{
                    width: 70%;
                    display: flex;
                    justify-content: flex-start;
                    .input-generic{
                        width: 100%;
                        padding: 10px;
                        border-radius: 18px;
                        border: none;
                        background-color: rgba(4 , 5, 5 , .2);
                        padding-left: 13px;
                        font-size: 1.1vw;
                    }
                }

            }
        }
        .footer-form{
            display: flex;
            align-items: center;
            justify-content: space-around;
            border-top: 2.5px solid #c1c1c1;
            padding-top: 15px;
        }

        // .footer-form.operative-buttons-container {
        //     padding-top: 0;

        // }
    }
}

.container-days{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-gap: 1vw;
    margin: 0;
    .active{
        background: rgba(0, 102, 179, 0.2) !important;
        color: rgb(0, 102, 179) !important;
        font-weight: 500;
        border: 2px solid  rgb(0, 102, 179) !important;
    }
    .btnDay{
        border-radius: 5px;
        width: 100%;
        border: 2px solid #707070;
        color: white;
        background: #707070;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        padding: 10px;
        font-size: 1.1vw;
        margin: 0;
    }
}

.input-time{
    display: flex;
    flex-direction: row-reverse;
    border-radius: 15px;
    border: none;
    background-color: rgba(4 , 5, 5 , .2);
    padding: 8px;
    font-size: 1.1vw;
    width: 45%;
    color: #707070;
}
.input-time[type="time"]::-webkit-calendar-picker-indicator {
    color: #707070;
  }

select{
    display: flex;
    flex-direction: row-reverse;
    border-radius: 15px;
    border: none;
    background-color: rgba(4 , 5, 5 , .2);
    padding: 10px;
    font-size: 1.1vw;
    width: 100%;
    color: #707070;
}