@import './../../styles//stylesSteppers.scss';

.header-data-container {
    // padding-left: 30px;
    display: flex;
    align-items: center;
}
.input-container-quotation {
    width: 95%;
    justify-content: space-between;
}
.body-form-generic-type-data {
    width: 100%;
    display: flex;
    flex-direction: column;
}
.body-data {
    width: 100%;
}

.modality-title {
    margin-right: 15px;
    @include label;
}
.container-label {
    @include label;
    color: #000;
    font-weight: 500;
}

.main-container-generic-type {
    .dropdown-container {
        img {
            width: 2%;
            padding: 4px;
        }
    }
}