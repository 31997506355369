// span{
//     color: #8b8b8b;
//     font-family: 'Poppins',sans-serif;
//     text-transform: uppercase;
//     font-size: 10px;
//    // z-index: 99;
// }

.prueba{
    background-color: #FFFF;
}

.pruebaDos{
    background-color: #efefef;
    display: flex;
    width: 100%;
}

.ordersRadio{
    background-color: #e5e1e1;
    display: flex;
    width: 100%;
}

.MuiTypography-body1{
    color: black;
}

.MuiFormControlLabel-root {
    justify-content: space-between;
    margin-right: 46px !important;
}

// .MuiButtonBase-root.MuiIconButton-root.PrivateSwitchBase-root-4.MuiRadio-root.makeStyles-root-1 {
    :root .MuiIconButton-label {
                // background-color: transparent;
                background-size: cover;
            }
    

.MuiFormControlLabel-root {
    flex-direction: row-reverse;
}
// .MuiFormControl-root {
//     display: grid !important;
//     grid-template-rows: 50% 50%;
// }