.main-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}
.main-container.admin-profile {
    padding: 20px 0 40px 0;
}

.main-container.admin{
    margin: 20px 0 40px 0;
    max-height: 90% !important;
}