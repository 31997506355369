.extraFooter{
    margin-top: 3rem;
}
.extraQuotation{
    height: 20rem;
}

.optionExtra{
    height: 48vh !important;
}

.main-containerClient{
    
}
.body-form-generic-type-client{
    overflow-y: auto;
    height: 22rem;
}
@mixin inputContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    // margin: 20px 0;
    width: 100%;
}

@mixin input {
    width: 100%;
    padding: 10px;
    border-radius: 20px;
    border: none;
    background-color: rgba(4 , 5, 5 , .2);
    padding-left: 13px;
    font-size: 1.1vw;
    text-transform: uppercase;
}

@mixin labelContainer {
    width: 30%;
    display: flex;
    justify-content: flex-end;
}

@mixin label {
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 1.1vw;
    color: #0066B3;
}

.main-containerAuto{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.main-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.card-container-stepper{
    width: 55%;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.3);
    // height: 70%;
    border-radius: 8px;
}
.stepper-container{
    width: 55%;
    margin: 1em 0 2em 0;
    height: 2em;
}
////select generic type
.header-form-generic-type{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #707070;
    padding-bottom: 15px;
    .id {
        font-family: "Montserrat";
        font-weight: 600;
        font-size: 1.1vw;
    }
}
.main-container-generic-type{
    width: 100%;
    padding: 1.2vw;
    background-color: #fff;
    border: 1px solid rgba(255,255,255,0.7);
    box-shadow: 0 3px 3px 0 rgba(155, 153, 153, 0.24), 0 3px 3px 0 rgba(153, 152, 152, 0.19);
}
.main-container-generic-type-quotation {
    width: 100%;
    padding: 1.2vw;
    background-color: #fff;
    border: 1px solid rgba(255,255,255,0.7);
    box-shadow: 0 3px 3px 0 rgba(155, 153, 153, 0.24), 0 3px 3px 0 rgba(153, 152, 152, 0.19);
}
.quotation-options-container {
    width: 100%;
    height: 40vh;
}
.main-container-generic-type-quotation.operative {
    .quotation-options-container.operative {
        width: 100%;
        height: 85%;
    }
}

.dropdown-container {
    width: 100%;
    display: flex;
    justify-content: center;
    img {
        width: 4%;
        padding: 4px;
    }
}
.body-form-generic-type {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1em 0 5em 0;
    flex-direction: column;
}
.input-container{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 20px 0;
    width: 100%;
    .label-container{
        width: 30%;
        display: flex;
        justify-content: flex-end;
        .container-label{
            font-family: "Montserrat";
            font-weight: 600;
            font-size: 1.1vw;
            color: #0066B3;
            text-align: end;
        }

    }
    .value-container{
        width: 70%;
        display: flex;
        justify-content: flex-start;
        .input-generic{
            width: 100%;
            padding: 10px;
            border-radius: 12px;
            border: none;
            background-color: rgba(4 , 5, 5 , .2);
            padding-left: 13px;
            font-size: 1.1vw;
        }
    }

}
.input-container-data{
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    margin: 20px 0;
    .label-container{
        width: 100%;
        display: flex;
        justify-content: flex-start;
        .container-label{
            font-family: "Montserrat";
            font-weight: 600;
            font-size: 1.1vw;
            color: #0066B3;
            text-align: end;
        }

    }
    .value-container{
        width: 100%;
        display: flex;
        justify-content: flex-start;
        .input-generic{
            width: 100%;
            padding: 10px;
            border-radius: 12px;
            border: none;
            background-color: rgba(4 , 5, 5 , .6);
            padding-left: 13px;
            font-size: 1.1vw;
        }
        .input-generic.date {
            display: flex;
            flex-direction: row-reverse;
        }
    }

}
.body-form-generic-type-data{
    overflow-y: scroll;
    .input-container{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        margin: 20px 0;
        .label-container{
            width: 30%;
            display: flex;
            justify-content: flex-end;
            .container-label{
                font-family: "Montserrat";
                font-weight: 600;
                font-size: 1.1vw;
                color: #0066B3;
                text-align: end;
            }

        }
        .value-container{
            width: 70%;
            display: flex;
            justify-content: flex-start;
            .input-generic{
                width: 100%;
                padding: 10px;
                border-radius: 12px;
                border: none;
                background-color: rgba(4 , 5, 5 , .2);
                padding-left: 13px;
                font-size: 1.1vw;
            }
            .input-generic.date {
                display: flex;
                flex-direction: row-reverse;
            }
        }

    }
}
.body-form-generic-type-docs{
    overflow: auto;
    height: 45vh;
    padding: 1em;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: center;
    align-items: flex-start;
    gap: 10px 7vw;
    .input-container{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        margin: 20px 0;
        .label-container{
            width: 30%;
            display: flex;
            justify-content: flex-end;
            .container-label{
                font-family: "Montserrat";
                font-weight: 600;
                font-size: 1.1vw;
                color: #0066B3;
                text-align: end;
            }

        }
        .value-container{
            width: 70%;
            display: flex;
            justify-content: flex-start;
            .input-generic{
                width: 100%;
                padding: 10px;
                border-radius: 12px;
                border: none;
                background-color: rgba(4 , 5, 5 , .2);
                padding-left: 13px;
                font-size: 1.1vw;
            }
            .input-generic.date {
                display: flex;
                flex-direction: row-reverse;
            }
        }

    }
}
.footer-form-generic-type{
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid #707070;
}

.footer-form-generic-type.operative {
    align-items: center;
}

.add-button-container{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .add-card{
        width: 30px;
    }
}
.card-container-generic-type{
    width: 55%;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.3);
    // height: 70%;
    border-radius: 8px;
}
.mid-body{
    width: 50%;
}
.picture-container{
    display: flex;
    justify-content: center;
    position: relative;
    .preContainer{
        position: relative;
    }
        .img-profile{
            width: 7rem;
            height: 7rem;
            overflow: hidden;
            margin: 2em;
            margin-bottom: 0;
            position: relative;
            border-radius: 50%;
        }
        .img-rewards{
            width: 13rem;
            height: 12rem;
            overflow: hidden;
            margin: 2em;
            margin-top: 0;
            position: relative;
            border-radius: 5px;
        }
        .container-input{
            display: inline-block;
            position: absolute;
            right: 2em;
            bottom: 0em;
            z-index: 1;
        .file{
            display: none;
        }
        .LeftInfoComponent-button{
                border: none;
                cursor: pointer;
                outline: none;
                border-radius: 0.5vw;
                /* margin-right: 2vw; */
                justify-content: center;
                align-items: center;
                display: flex;
                text-align: center;
            .img-add{
                width: 3vw;
                height: 3vw;
                cursor: pointer;
            }
        
            
        }
    }
}
.customer-agent-container{
    width: 100%;
    display: flex;
    justify-content: center;
}
.customer-agent-row{
    display: grid;
    /* justify-content: center; */
    align-items: center;
    grid-template-columns: 3fr 1fr 2fr;
    border-bottom: 1px solid black;
    padding: 8px 0px;
    width: 95%;
    img{
        width: 30px;
    }
    button{
        background-color: black;
        margin: 1em 0;
    }
}
.button-head-container{
    display: flex;
    justify-content: flex-end;
    padding: 1em 0;
}

.body-form-generic {
    .create-port-container {
        border: none;
        display:flex;
        justify-content: right;
    }
    .port-data-container {
        width: 100%;
        height: 25vh;
        overflow-y: scroll;
    }

    .port-form-container {
        border-top: none;
    }
    .add-button-container {
        padding-bottom: 10px;
    }
}