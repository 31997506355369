@import '../../../styles/mixinsAndVariables';

.imageContent {
    position: absolute;
    top: 3rem;
    left: 35%;
}

.modal-content {
    width:max-content !important;
    height: fit-content !important;
    border-radius: 7px;
    background-color: #ffffff;
    padding: 1rem;
    // padding-top: 0rem;
    max-height: 75vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .modal-title {
        &:after {
            content: '';
            position: absolute;
            bottom: -4px;
            width: 38%;
            height: 2px;
            background-color: #0085CA;
        }
    }

    .closeIcon {
        width: 90%;
        margin-right: 2.5rem;
        .close-icon-modal {
            cursor: pointer;
        }
        .redIcon {
            color: $buttons-red;
        }
    }
}


.formModalReportSelect {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
}

.formModalReportSelect__dateContainer {
    display: flex;
    flex-direction: row;
}

.inputDateModal {
    width: 120px;
    height: 30px;
    border-radius: 15px;
    padding: 2px 15px;
    margin: 5px;
    border-style: none;
    border: 1px solid rgb(200, 198, 198);
}

.inputDateModal.first {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    margin-right: 0;
    border-right: 0;
}

.inputDateModal.second {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    margin-left: 0;
    border-left: 0;
}

.generateReportButton {
    @include buttonFormKoala($buttons-red);
    margin-top: 5%;
    margin-bottom: 10%;
    width: fit-content;
}

.ViewReportModal__closeIcon {
    // margin-top: 20px;
    // padding-top: 0 !important;
    .redIcon {
        color: $buttons-red;
    }
}
.typeOfReportModal__button {
    margin-top: 0;
    margin-bottom: 70px;
}

.ViewReportModal__formContainer {
    width: 90%;
    min-width: 380px;
    display: flex;
    flex-direction: column;
    margin-bottom: 60px;
}

.formModalReportSelect__option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    margin: 0;
}

.formModalReportSelect__titleReports {
    margin: 20px;
}

.FirstSpaceline {
    margin-bottom: 5%;
}

.SecondSpaceline {
    margin-top: 15%;
}

.option__label {
    margin: 0;
    padding: 0;
}

.SelectDateReportModal__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 30px;
    margin-top: 0;
    .SelectDateReportModal__title {
        color: #000;
        font-size: 24px;
        margin-bottom: 15px;
    }
    .SelectDateReportModal__formContainer {
        display: flex;
        flex-direction: column;
        width: 450px;

        .SelectDateReportModal__radioButton {
            margin: 4px;
        }
    }
    .SelectDateReportModal__button {
        margin-bottom: 50px;
    }
}
.formModalReportSelect {
    .changeNameInput {
        width: 350px;
        border-radius: 3px;
        border: 1px solid rgb(178, 178, 178) ;
        padding: 3px;
        margin: 15px 50px;
    }
}

.deleteModal__generalContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 30px;
    margin-top: 50px;
    .deleteModal__title {
        font-weight: 600;
        font-size: 30px;
        // margin: 10px;
        // margin-top: 0px;
    }
    .deleteModal__subtitle {
        font-size: 22px;
        margin: 18px;
    }
    .deleteModal__buttonContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 15px;
        margin-bottom: 25px;
        .deleteModal__Button {
            margin: 0 10px;
            font-size: 18px;
        }
    }
}

.modalContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: hidden !important;
    .EditReportNameModal {
        width: 100%;
        margin: 30px;
        margin-top: 0;
        padding: 10px;
        padding-top: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .formEditReportNameModal {
            width: 100%;
            .formEditReportNameModal__title{
                font-size: 30px;
                font-weight: 700;
                margin: 20px;
            }
            .formEditReportNameModal__input{
                border: 1px solid rgb(161, 160, 160);
                border-radius: 5px;
                width: 350px;
                padding: 5px;
                font-size: 14px;
                margin-bottom: 10px;
            }
            .formEditReportNameModal__button {
                margin: 20px;
                margin-bottom: 10px;
                font-size: 14px;
            }
        }
    }
}

.chargingkoala__container {
  width: fit-content;
  height: fit-content;
}
