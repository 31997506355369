.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed{
    color: #707070 !important;
}
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
    color: #0066B3 !important;
}
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root {
    color: #707070 !important;
}
.css-117w1su-MuiStepIcon-text {
    fill: transparent !important;
    font-size: 0.75rem;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
}
.css-qivjh0-MuiStepLabel-label {
    font-family: "Gotham",sans-serif !important;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    display: block;
    color: #707070 !important;
    -webkit-transition: color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.css-qivjh0-MuiStepLabel-label.Mui-completed {
    color: #707070 !important;
    font-weight: 500;
}
.css-qivjh0-MuiStepLabel-label.Mui-active {
    color: #707070 !important;
    font-weight: 500;
}
.adelante{

    border: 1px solid #0066B3;
    color: #0066B3;
  }
  .adelante:hover{
    background-color: #0066B3;
    border: 1px solid #0066B3;
    color: #fff;
  }
  