.inputDate {
    width: 18%;
    height: 30px;
    border-radius: 15px;
    padding: 2px 15px;
    margin: 5px;
    margin-left: 15px;
    border-style: none;
    border: 1px solid rgb(200, 198, 198);

    &.first {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
        border-right: none;
        margin-right: 0;
        padding-right: 5px;
    }
    
    &.second {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        border-left: none;
        margin-left: 0;
        padding-left: 5px;
    }
}

.inputSearchContainer {
    display: flex;
    justify-content: left;
}
.inputSearch {
    width: 35%;
    background-position: 10px 50%;
    background-repeat: no-repeat;
    background-size: 15px;
    padding: 8px;
    border-radius: 15px;
    border: none;
    background-color: rgba(4, 5, 5, 0.2);
    padding-left: 39px;
    color: #040505;
}