.form-item {
    display: flex;
    min-width: 500px;
    margin-bottom: 1rem;
    
    .left-container {
        width: 30%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding-right: 1rem;
        align-items: center;
    }

    .label-item {
        color: #000;
        font-size: 18px;
        margin-bottom: 5px;
    }

    .right-container {
        width: 70%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        padding-left: 1rem;
    }
}

