@import './../../styles/stylesSteppers.scss';

.view-details-general-container.provider {
    width: 90%;
    height: 90%;
    display: flex;
    justify-content: center;
    // margin-left: 15%;
    margin-top: 2%;
    align-items: center;
}
.card-data-container {
    background-color: #fff;
    box-shadow: 2px 2px 2px 2px rgb(236, 235, 235);
    border-radius: 10px;
    height: 95%;
    width: 30%;
    padding: 20px;
    flex-direction: column;
    margin: 2% 30px 35px 40px;
    // align-items: center;
    // margin-right: 30px;
    display: grid;
    grid-template-rows: 50% 30% 20%;
    align-items: center;
    .provider-data-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        .logo-provider-container {
            margin-top: 10%;
            // background-color: #0066B3;
            width: 10vw;
            height: 10vw;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            img {
                // width: 70%;
                width: inherit;
            }
        }
        
        .header-data-container {
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding: 0;
            .title-text {
                font-weight:500;
                color: #000;
                font-family: "Montserrat";
                font-size: 1.1rem;
            }
        }
        .one-data-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            h4 {
                margin-top: 10px;
                font-weight:700;
                color: #000;
                font-family: "Montserrat";
                font-size: 1.1rem;
            }
            .client-info {
                text-align: center;
                color: #0066B3;
                font-family: "Montserrat";
                font-weight:700;
                font-size: .9rem;
            }
        }
    }
    .buttons-container.provider {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 0;
        padding-top: 0;
        .input-upload-logo {
            display: none;
        }
        .button-upload-logo.provider {
            width: 90%;
            margin: 0;
            border-radius: 30px;
            &:hover {
                transform: translateY(-3px);
                box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
                background-color:rgba(189, 15, 29, 0.9);
            }
        }
    }
    .id-data-container{
        width: 100;
        height: 10%;
        display: flex;
        flex-direction: column;
        padding-top: 5%;
        .id-provider {
        display: flex;
        justify-content: left;
        align-items: center;
            .title-text, .id {
                // padding-right: 5px;
                padding-left: 8px;
                font-weight:200;
                color: #000;
                font-family: "Montserrat";
                font-size: .8rem;
            }
        }
    }
}


.main-container.provider-stepper {
    width: 70%;
    border-radius: 10px;
    padding: 0px 10px 35px 10px;
    height: 98%;
    display: flex;
    flex-direction: column;
    overflow-x: scroll;
    h3 {
        font-size: 1.2rem;
        font-family: "Montserrat";
    }
    .stepper-container {
        width: 100%;
        height: 8%;
    }
    .card-container-stepper {
        width: 100%;
        height: 92%;
    }
    .main-container-generic-type-quotation {
        height: 100%;
        display: grid;
        // grid-template-rows: 16% 60% 24%;
    }
    .footer-form-generic-type {
        height: 20%;
        // button {
        //     margin-top: 15px;
        //     padding: 0;
        // }
    }

       .provider-form-data-container .footer-form-generic-type {
            display: flex;
            align-items: center;
            .button-next {
                margin: 0;
            }
            .button-back {
                margin: 0;
            }
        }
    }
.provider-documents-container {
    height: 50%;
    width: 100%;
    .quotation-data {
        width: 100%;
        .body-form-docs.provider {
            width: 97%;
            display: grid;
            grid-template-columns: 30% 30% 30%;
            justify-content: space-evenly;
            .uploadFileCardContainer {
                width: 100%;
                margin: 15px;
                margin-left: 0;
            }
        }
    }
}
.footer-form-generic-type {
    height: 10%;
}