.main.mainTemplate.container-fluid {
    padding: 0;
}
.route-record-general-container {
    display: flex;
    width: 100%;
    height: 100%;
    .route-record-map-container {
        width: 55%;
        height: 95.5%;
        background-color: rgb(127, 219, 255);
    }
    .route-record-data-container {
        display: flex;
        padding-left: 20px;
        padding-top: 20px;
        justify-content: left;
        align-items: flex-start;
        height: 100%;
        width: 50%;
        padding-top: 0px;
        flex-direction: column;
        .data-record-card-container {
            width: 75%;
            height: 80%;
            overflow-y: scroll;
            border-radius: 15px;
            display: grid;
            grid-template-rows: 10% 10% 70% 10%;
            box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.3);
            background-color: #fff;
            padding: 15px 30px;
            .header-form-generic-type {
                display: flex;
                justify-content: space-between;
                width: 100%;
                font-family: 'Montserrat';
                h4 {
                    text-align: end;
                }
            }
            .body-route-declaration-container {
                width: 100%;
                height: 100%;
                .places-container {
                    padding: 5px 0;
                    display: flex;
                    justify-content: space-between;
                    font-family: 'Montserrat';
                }
                .circle-pointers-container {
                    display: grid;
                    grid-template-columns: 5% 90% 5%;
                    width: 100%;
                    align-items: center;
                    margin-top: 15px;
                    .line-between {
                        border-bottom: 1px dashed #959798;
                        height: 1px;
                    }
                    .line-between.active {
                        border-bottom: 1px dashed #0966d7;
                    }
                    .circle-boarder {
                        border: 1px solid #959798;
                        width: 15px;
                        height: 15px;
                        border-radius: 10px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        .circle {
                            width: 7px;
                            height: 7px;
                            border-radius: 5px;
                            background-color: #959798;
                        }
                    }
                    .circle-boarder.active {
                        border: 1px solid #0966d7;
                        .circle {
                            background-color: #0966d7;
                        }
                    }
                }
            }
            .buttons-container {
                // background-color: rgb(173, 118, 118);
                border-top: 1px solid rgb(134, 134, 134);
                width: 100%;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                .refresh-button {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 25px;
                    width: fit-content;
                    background-color: #D7091B;
                    padding: 8px;
                    margin: 15px 0 0 0;
                    width: 38px;
                    height: 38px;
                    img {
                        width: 17px;
                        margin: 4px;
                    }
                }
            }
            .status-data-container {
                width: 100%;
                height: 100%;
                padding-top: 25px;
                padding-bottom: 25px;
                overflow-y: scroll;
                .checkpoint-container {
                    display: flex;
                    justify-content: left;
                    align-items: center;
                    .checkpoint {
                        width: 13px;
                        height: 13px;
                        background-color: #0966d7;
                        border-radius: 8px;
                        margin-right: 20px;
                    }
                    .place {
                        color: #000;
                        font-weight: 500;
                        font-family: 'Montserrat';
                        font-size: 14px;
                    }
                }
                .line-between-checkpoints-container {
                    display: flex;
                    align-items: center;
                    .line-between-checkpoints {
                        border-right: 1.5px solid #0966d7;
                        width: 1px;
                        height: 25px;
                        margin-left: 5.5px;
                        margin-right: 25px;
                    }
                    .line-between-checkpoints.none {
                        border-right: 1.5px solid transparent;
                    }
                    .status-container {
                        display: flex;
                        font-size: .8vw;
                        color: #878787;
                        font-family: 'Montserrat';
                        .space {
                            margin: 0 4px;
                        }
                    }
                }
            }
            .footer-record-route {
                height: 100%;
                width: 100%;
                display: flex;
                justify-content: left;
                font-family: 'Montserrat';
                align-items: center;
                padding-top: 15px;
                // background-color: palevioletred;
                .button-radio {
                    border: 1px solid rgb(105, 105, 105);
                    border-radius: 10px;
                    width: 15px;
                    height: 15px;
                    margin: 1vw 1vw .8vw .5vw;
                }
            }
        }
    }
}