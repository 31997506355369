.closure-record-container {
    position: absolute;
    right: 0;
    width: calc(100vw - 160px);
    height: 100vh;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    padding-top: 10%;
    background-color: #fff;
    .closure-record-image-container {
        width: 6.5rem;
        height: 6.5rem;
        margin-bottom: 30px;
        img {
            width: inherit;
            height: inherit;
        }
    }
    .closure-record-info-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        font-family: 'Montserrat';
        color: #000;
        .text-info-container {
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            .closure-record-info {
                font-family: 'Montserrat';
                margin: 5px 0;
            }
        }
    }
}