.assign-form{
    width: 100%;
    height: 10em;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    .assign-form-button-container {
        display: flex;
        width: 100%;
        justify-content: right;
        align-items: center;
        margin-top: 35px;
    }
    .filter-container {
        padding-left: 4.3vw;
        width: 100%;
        .inputSearch {
            width: 50%;
        }
    }
}
.input-container-data-form{
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    margin: 5px 0 0 0;
    .label-container.modality {
        margin-top: 75px;
    }
    .label-container{
        width: 100%;
        display: flex;
        justify-content: flex-start;
        .container-label{
            font-family: "Montserrat";
            font-weight: 600;
            font-size: 1.1vw;
            color: #0066B3;
        }

    }
    .value-container{
        width: 100%;
        display: flex;
        justify-content: center;
        .input-generic{
            width: 100%;
            padding: 10px;
            border-radius: 12px;
            border: none;
            background-color: rgba(4 , 5, 5 , .6);
            padding-left: 13px;
            font-size: 1.1vw;
        }
        .input-generic.date {
            display: flex;
            flex-direction: row-reverse;
        }
    }

}
.value-container-radio-buttons {
    width: 100%;
    display: flex;
    justify-content: start;
    margin-left: 110px;
}
// .value-container-radio-buttons{
//         .MuiFormControlLabel-root .MuiFormGroup-root .MuiFormControlLabel-root{
//                 .MuiButtonBase-root.MuiIconButton-root.PrivateSwitchBase-root-4.MuiRadio-root.makeStyles-root-1{
//                     .MuiIconButton-label{
//                        background-color: transparent !important;
//                    }
//                 }
            
//         }
    
// }
