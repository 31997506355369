.error-message{
    font-size: 12px;
    color: #7c1e1e;
    margin-top: 5px;
}
@import '../../../styles/mixinsAndVariables';

main {
    @include Reset;
}

.buttonsContainer {
    @include buttons;
}

main section {
    @include mainSectionKoala;
    .container-image-koala {
        @include containerImageKoala;
    }
    .form-container {
        @include formContainer(18%);
        .buttons-log-sign {
            align-self: flex-end;
        }
        div .log {
            @include buttonPillLoginKoala;
            @include buttonActive;
            }
        div .sign {
            @include buttonPillSignupKoala;
            @include buttonInactive;
        }
        .form-password {
            @include formBasicKoala;
            .email {
                @include formDiv(35px, 20px);
                label {
                    @include labelsKoala;
                }
                input {
                    @include inputsKoala;
                }
                Button {
                    @include buttonFormKoala;
                }
            }
        }
    }
}
footer {
    @include footerStyle();
}
