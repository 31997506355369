.HomeTableSection__containerButtons {
    margin-bottom: 20px;
    width: 35%;
    .input-container-data-form {
        width: 35%;
        .label-container {
            margin-left: 20px;
        }
    }
}

.records-documentation-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    .main-container-generic-type.records {
        width: 70%;
        height: 80%;
        border-radius: 15px;
        .body-form-generic-type-docs.record-documentation {
            overflow-y: scroll;
            height: 58vh;
        }
    .header-form-generic-type {
        h4 {
            font-family: "Montserrat";
            font-weight: 400;
            font-size: 1.3rem;
        }
        h2 {
            font-family: "Montserrat";
            font-weight: 600;
            font-size: 1.6rem;
        }
    }
}
}
