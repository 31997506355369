.uploadFileCardContainer{
    width: 100%;
    height: 100%;
    .titleCardFiles{
        height: 3em;
        p{
            color: #0066B3;
            font-family: 'Montserrat';
            font-weight: 500;
        }
    }
    :hover{
        .deleteFile{
            display: flex;
            gap: 10px;
        }
        .uploadedFileView{
            img{
                width: 1.4vw;
                margin-bottom: 3px;
            }
        }
    }
    .deleteFile{
        position: absolute;
        width: 100%;
        height: 100%;
        // display: flex;
        justify-content: center;
        align-items: center;
        display: none;
        background-color: rgba(4, 5, 5, .7);
        border-radius: 8px;
        transition: all .5s ease-in-out;
        img{
            width: 45px;
        }
    }
    .uploadedFileArea{
        background-color: rgba(0, 0, 0, 0.05);
        border-radius: 8px;
        justify-content: center;
        align-items: center;
        display: flex;
        padding: 0.8vw;
        flex-direction: column;
        box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.3);
        height: 11em;
        position: relative;
        .uploadedFileView{
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            height: 60%;
            color: black;
            img{
                width: 1.7vw;
                margin-bottom: 3px;
            }
        }
        .uploadedFileText{
            width: 100%;
            height: 40%;
            display: flex;
            justify-content: center;
            padding-top: 5px;
            text-align: center;
            p{
                font-size: .6vw;
            }
        }
    }
    .uploadFileArea {
        position: relative;
        background-color: rgba(0, 0, 0, 0.05);
        border-radius: 8px;
        justify-content: center;
        align-items: center;
        display: flex;
        padding: 0.8vw;
        flex-direction: column;
        box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.3);
        height: 11em;
        .inputUploadFileArea {
            // visibility: hidden;
            opacity: 0;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
        }
        .headerCardFiles{
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            height: 40%;
            color: black;
            .p1{
                font-size: .9vw;
                font-weight: 500;
            }
            .p2{
                margin-top: 3px;
                font-size: 0.5vw;
                font-weight: 600;
            }
        }
        .bodyCardFiles{
            width: 100%;
            height: 60%;
            background-color: rgba(0, 102, 179, 0.1);
            border-radius: 5px;
            border: 3px dashed rgb(0, 102, 179);
            .drag-area{
                width: 100%;
                height: 100%;
                display: flex;
                text-align: center;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                padding: 4px;
                img{
                    width: 1.7vw;
                    margin-bottom: 3px;
                }
                p{
                    font-size: .6vw;
                }
            }
        }
    }
}