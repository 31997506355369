.cardsContainer {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.CardClientStadisticContainer {
    display: flex;
    flex-direction: row-reverse;
    width: 300px;
    background-color: #fff;
    border-radius: 10px;
    justify-content: space-between;
    padding: 10px;
}

// .bothImagesContainer {

// }

.bothImages {
    width: 30px;
    margin: 5px;
}

.imageStoreContainer {
    display: flex;
    justify-content: right;
}

.imageStore {
    width: 60px;
    height: 60px;
}

.listContainer {
    width: 100%;
    height: fit-content;
    display: flex;
}

.CardTextContainer__titleOne {
    font-size: 14px;
    font-weight: 100;
    color: rgb(190, 188, 188);
}

.CardTextContainer__titleTwo {
    font-size: 18px;
    color: rgb(133, 131, 131);
    margin-bottom: 15px;
}

.CardTextContainer__number {
    font-size: 28px;
    color: #000;
    font-weight: 700;
}

.cardsContainerDate2, .cardsContainerDate1 {
    padding: 5px;
    min-width: 100px;
    border-radius: 10px;
    border: rgb(139, 138, 138);
    margin: 5px 0;
}

.clientHome__cardsFilterContainer {
    display: flex;
    flex-direction: column;
    margin: 10px;
}
