@import '../../../styles/stylesSteppers.scss';

.view-details-general-container {
    width: 94%;
    height: 75%;
    display: flex;
    margin-left: 3%;
    margin-top: 3%;
}

.general-data-container {
    width: 35%;
    display: flex;
    flex-direction: column;

    .client-data-container {
        padding: 20px;
        height: 55%;
        width: 95%;
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 2px 2px 2px 2px rgb(236, 235, 235);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-evenly;

        .header-data-container {
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding: 0;

            .title-text {
                font-weight: 500;
                color: #000;
                font-family: "Montserrat";
                font-size: 1.1rem;
            }

            .id-quotation {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .id {
                    // padding-right: 5px;
                    padding-left: 8px;
                    font-weight: 500;
                    color: #000;
                    font-family: "Montserrat";
                    font-size: 1.1rem;
                }
            }
        }

        .one-data-container {
            text-align: center;

            h4 {
                font-weight: 700;
                color: #000;
                font-family: "Montserrat";
                font-size: 1.1rem;
                text-align: left;
            }

            .client-info {
                color: #000;
                font-family: "Montserrat";
                font-size: .9rem;
                text-align: left;
            }
        }
    }

    .buttons-container {
        padding-top: 15px;
        margin-top: 20px;
        height: 35%;
        width: 95%;
    }
}

.data-section-container {
    width: 100%;
    display: grid;
    grid-template-columns: 33.33% 33.33% 33.33%;
}

.quotation-container {
    width: 70%;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 2px 2px 2px 2px rgb(236, 235, 235);
    padding: 25px;
    height: 100%;
    display: grid;
    grid-template-rows: 8% 90% 2%;

    h3 {
        font-size: 1.2rem;
        font-family: "Montserrat";
    }

    .dropdown-container {
        padding-top: 5px;
        width: 100%;
        display: flex;
        justify-content: center;

        img {
            width: 23px;
            height: 23px;
        }
    }

    .quotation-data {
        height: 100%;
        overflow-y: scroll;
        width: 98%;
        padding: 0 5px;
        display: flex;
        flex-direction: column;
        .value-container-principal-data, .value-container-origin-data, .value-container-destiny-data, .value-container-other {
            margin-bottom: 30px;
        }

        .input-container-form {
            @include inputContainer;
            flex-direction: column;
            margin: 5px 15px;

            .label-container {
                @include labelContainer;
                width: 100%;
            }

            .container-label {
                @include label;
                width: 90%;
            }
        }
        .value-container {
            width: 85%;
            .input-generic {
                @include input;
            }
            .input-generic.date {
                display: flex;
                flex-direction: row-reverse;
            }
        }
        .value-container-select {
            width: 85%;
            .input-select {
                @include input;
            }
        }
        .value-container-total-amount {
            padding: 5px;
            width: 100%;
            border-top: 1px solid rgb(131, 130, 130);
            display: flex;
            flex-direction: column;
            align-items: flex-end;
        }
        .input-container-total {
            width: 20vw;
            margin: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;

            .label-container {
                width: 30%;
                display: flex;
                justify-content: flex-end;

                .container-label {
                    font-family: "Montserrat";
                    font-weight: 600;
                    font-size: 1.1vw;
                    color: #0066B3;
                    text-align: end;
                    width: 100%;
                }
            }

            .value-container {
                width: 70%;
                display: flex;
                justify-content: flex-start;

                .input-generic {
                    width: 100%;
                    padding: 10px;
                    border-radius: 12px;
                    border: none;
                    background-color: rgba(4, 5, 5, .2);
                    padding-left: 13px;
                    font-size: 1.1vw;
                    text-transform: uppercase;
                }
            }

        }
    }
}