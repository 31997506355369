@import './../.././styles/mixinsAndVariables';

.SectionHeader__container {
    display: flex;
    flex-direction: row;
    margin-bottom: 3rem;
    border-bottom: 1px solid black;

    .SectionHeader__title {
        font-size: 24px;
        font-weight: bold;
        color: $buttons-red;
    }
}