.mainTemplate.mainTemplateClose.container-fluid {
    padding: 0;
}
.homeBackgroundContainer {
    width: 100%;
    height: 100%;
    background-position: inherit;
    background-image: inherit;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: fixed;
    right: 0;
    bottom: 0;
    .backgroundcover {
        width: 100%;
        height: 100%;
        background-color: rgba(51, 53, 83, 0.5);
        img {
            width: 40%;
            position: fixed;
            // bottom: 40;
            top: 40%;
            left: 38%;
        }
    }
}