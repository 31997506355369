@import './../../../../styles/styles';

html, body {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 0;
}
main.mainTemplate {
    position: absolute;
    right: 0px;
    padding-top: 12vh !important;
    width: calc(100vw - 260px);
    transition: all .2s ease-in-out;
    background-color: #F8F9FA;
    height: 100%;
    overflow: auto;
    justify-content: flex-start;
    flex-grow: 1;
    &.container-fluid {
        padding: 15px;
    }
}
main.mainTemplateClose{
    width: calc(100vw - 160px);
    transition: all .2s ease-in-out;
}
::-webkit-scrollbar {display: none;}

