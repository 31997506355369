// @import '~react-notifications-component/dist/theme.css';
// @import '~react-redux-notify/dist/ReactReduxNotify.css';
// @import '~react-toastify/dist/ReactToastify.css/';
// @import 'variables';
// @import 'maps';
// @import 'mixins';
// @import 'functions';

@import 'mixinsAndVariables';

@import "~react-alice-carousel/lib/scss/alice-carousel.scss";

:root {
  --alert-dark: #d88a02;
  --b-w-1: #000000;
  --danger-dark: #c5274d;
  --warning-light: #fa7e58;
  --links-light: #6cb6f5;
  --b-w-4: #ececec;
  --success-dark: #11b78c;
  --links-dark: #4897d9;
  --danger-light: #ef476f;
  --b-w-3: #c4bfc1;
  --warning-dark: #e55a2f;
  --b-w-2: #777274;
  --alert-light: #f5a61d;
  --b-w-6: #ffffff;
  --success-light: #15db93;
  --primary-1: #048aa8;
  --primary-2: #1d3557;
  --b-w-5: #fafafa;

    $blue_base: #004365;
}

/* Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body, html {
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
  font-family: "Nunito Sans", sans-serif;
  line-height: 1.6;
  color: #fff;
}


section {
  display: flex;
  flex-direction: column;
  /*align-items: center;*/
  align-content: flex-start;
  justify-content: center;
  text-align: center;
  padding: 2rem;
  width: 100%;
  // background-color: #fff;
  border-radius: 15px;
  flex-wrap: wrap;
  // box-shadow: 2px 2px 2px 2px rgb(216, 216, 216);
  height: auto;
}

.section-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.5rem;
}

.section-container-max-width {
  width: 1110px;
  max-width: 1110px;
}

.section-container-max-height {
  height: calc(100vh - 80px);
}