.main-container-card-data{
    width:max-content !important;
    padding: 20px;
}
.header-form-card-data{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #707070;
    padding-bottom: 15px;
}
.body-form-card-data-data{
    overflow: auto;
    height: fit-content !important;
}
.body-form-card-data{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1em 0 5em 0;
    flex-direction: column;
}
.footer-form-card-data{
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid #707070;
    Button.modal-card {
        margin-top: 25px;
        margin-bottom: 0;
    }
}
