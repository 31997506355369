@import './../../styles/stylesSteppers.scss';

.quotation-form-general-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;

    .value-container-principal-data, .value-container-origin-data, .value-container-destiny-data, .value-container-other {
        // grid-template-rows: 25% 25% 25% 25%;
        margin-bottom: 30px;
    }
    .data-section-container {
        display: grid;
        grid-template-columns: 33.33% 33.33% 33.33%;
    }
}

.value-container-total-amount {
    padding: 5px;
    width: 100%;
    border-top: 1px solid rgb(131, 130, 130);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.input-container-total {
    width: 20vw;
    margin: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    .label-container{
        width: 30%;
        display: flex;
        justify-content: flex-end;
        .container-label{
            font-family: "Montserrat";
            font-weight: 600;
            font-size: 1.1vw;
            color: #0066B3;
            text-align: end;
            width: 100%;
        }
    }
    .value-container{
        width: 70%;
        display: flex;
        justify-content: flex-start;
        .input-generic{
            width: 100%;
            padding: 10px;
            border-radius: 20px;
            border: none;
            background-color: rgba(4 , 5, 5 , .2);
            padding-left: 13px;
            font-size: 1.1vw;
        }
        .input-generic.date {
            display: flex;
            flex-direction: row-reverse;
        }
    }

}
.input-container-form {
    @include inputContainer;
    flex-direction: column;
    margin: 5px 10px;
    .label-container {
        @include labelContainer;
        width: 70%;
        justify-content: left;
    }
    .container-label {
        @include label;
        justify-self: left;
    }
}

.value-container {
    width: 70%;
    .input-generic {
        @include input;
    }
    .input-generic.date {
        display: flex;
        flex-direction: row-reverse;
    }
}

.value-container-select {
    width: 70%;
    .input-select {
        @include input;
    }

}

.data-section-title {
    color: rgb(38, 37, 37);
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 1.1vw;
    margin-bottom: 10px;
    margin-top: 15px;
}

.title-modality-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    margin: 20px 0;
    padding-right: 30px;
    width: 100%;
}
