
.mainNotFoundView {
    width: 100vw;
    height: 96vh;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-size: cover;
}

.mainNotFoundView__message {
    font-size: 50px;
}

.mainNotFoundView__link {
    font-size: 25px;
}