.deviations-modal-container {
    width: 550px;
    height: 450px;
    padding: 15px;
    padding-bottom: 0;
    display: grid;
    grid-template-rows: 15% 26% 10% 40% 12%;
    // flex-direction: column;
    .deviations-title-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: left;
        h3 {
            font-size: 1.6rem;
            color: #000;
            margin-bottom: 15px;
        }
    }
    .deviations-select-container {
        overflow-y: scroll;
        padding-top: 20px;
    }
    .add-button-container {
        width: 100%;
        height: 50px;
    }
    .deviation-reason {
        // label {
        //     margin-bottom: 5px;
        // }
        .reasons-deviations-input {
            width: 95%;
            height: 110px;
            border: none;
            background-color: rgba(4, 5, 5, 0.2);
            border-radius: 10px;
            margin: 10px 20px 20px 10px;        
            padding: 10px;
            text-align: start;
        }
    }
    .footer-form-generic-type {
        padding: 5px;
        button {
            margin-top: 14px;
            margin-bottom: 4px;
        }
    }
}