.menuOptions {
    background-color: #707070;
    color: #fff;
    // &:hover {
    //     background-color: #D7091B;
    img {
        margin-right: 5px;
        width: 15px;
    }
    
        .MuiPaper-root .MuiMenu-paper .MuiPopover-paper .MuiPaper-elevation8 .MuiPaper-rounded{
            .MuiMenu-list{
                background-color: #707070 !important;
            }
        }
    
    // .MuiList-root{
    //     background: #707070 !important;
    //     .MuiList-padding {
    //         background-color: #707070;
    //         }
    // }
    // .MuiMenu-list{
    //     background: #707070 !important;
    //     .MuiList-padding {
    //     background-color: #707070;
    //     }
    // }
    .MuiListItem-button:hover, .MuiListItem-button:focus {
        background-color: #D7091B;
    }
    border-bottom: 1px solid whi
}

