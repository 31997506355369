.card-container-generic-type-delivery {
    width: 100%;
}
.body-form-generic-type.records {
    width: 100%;
    display: flex;
    flex-direction: column;
    .input-column-container.delivery {
        width: 100%;
        display: grid;
        grid-template-columns: 50% 50%;
        justify-content: left;

    }
}
.input-container-delivery {
    margin-top: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: left;
    padding: 10px;
    .value-container {
        width: 100%;
        .input-time.records {
            width: 100%;
        }
    }
    .label-container {
        width: 100%;
        display: flex;
        justify-content: left;
        .container-label {
            font-size: 1rem;
            width: 100%;
            justify-content: left;
            text-align: start;
        }
    }
}