@import '../../styles/mixinsAndVariables';

// .tab {
//     overflow: hidden;
//     border: 1px solid #ccc;
//     background-color: #f1f1f1;
//     width: 400px;
//     text-align: center;
//     display: flex;
//     align-items: center;
//     justify-content: space-around;
//   }
  
// .tab button {
//     background-color: inherit;
//     width: auto;
//     float: left;
//     border: none;
//     outline: none;
//     cursor: pointer;
//     padding: 14px 30px;
//     transition: 0.3s;
//   }
  
// .tab button:hover {
//     background-color: #ddd;
//   }
  
// .tab button.active {
   
//   }

  .tab-list-item {
    display: inline-block;
    list-style: none;
    text-align: center;
    margin-top: 60px;
    margin-bottom: -1px;
    background-color: rgb(152, 150, 150);
    color:#fff;
    font-weight: 500;
    width: 110px;
    height: 40px;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 13px 0px;
    transition: 0.3s;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
  
  .tab-list {
    // border-bottom: 1px solid #ccc;
    padding-left: 0;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }

  .tab-list-active {
    border: solid #ccc;
    border-width: 1px 1px 0 1px;
    background-color: #bf4737;
    color: #fff;
    font-weight: 700;
  }

  .tab-content {
    background: #fff;
    color: #000;
    font-size: 30px;
    border-radius: 15px;
  }