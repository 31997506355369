// .form input {
//     display: none;
//     // appearance: none;
//     // visibility: hidden;
    
// }
// .buttonLabel {
//     color:#ffdc00;
//     padding: 5px 15px 5px 51px;
//     display: inline-block;
//     position: relative;
//     font-size: 16px;
//     border-radius: 3px;
//     cursor: pointer;
// }

// .buttonLabel:hover {
//     color: #b4a22b;
// }

// .buttonLabel:before {
//     content: "";
//     width: 17px;
//     height: 17px;
//     display: inline-block;
//     background: none;
//     border: 3px solid #ffdc00;
//     border-radius: 50%;
//     position: absolute;
//     left: 17px;
//     top: 7px;
// }
// .form input[type="radio"]:checked + .buttonLabel {
//     background: #b4a22b;
// }

* {
  appearance: none;
}

label {
  position: relative;
}

  
li input {
  position: absolute;
  visibility: hidden;
  right: 0;
}

// label {
//   position: relative;
//   margin-bottom: 30px;
//   padding: 5px 0 5px 60px;
//   display: block;
// }

.radioButton {
  visibility: hidden;
  position: absolute;
  right: 0;
}

label + .form {
  background: #f0f0f0;
  border: 2px solid rgba(0,0,0,0.2);
  position: absolute;
  left: 0;
  top: 0;
}

label .radioButton + .form {
  height: 30px;
  
}

  
  .radioButton {
    display: block;
    position: relative;
    font-weight: 300;
    font-size: 1.35em;
    padding: 25px 25px 25px 80px;
    margin: 10px auto;
    height: 30px;
    z-index: 9;
    cursor: pointer;
    // -webkit-transition: all 0.25s linear;
  }
  
  .radioButton:hover, .label:hover {
      color: #FFFFFF;
  }
  
  .radioButton .form{
    display: block;
    position: absolute;
    border: 5px solid #AAAAAA;
    border-radius: 100%;
    height: 25px;
    width: 25px;
    top: 30px;
    left: 20px;
      z-index: 5;
      transition: border .25s linear;
      -webkit-transition: border .25s linear;
  }
  
  label:hover .form {
    border: 5px solid #FFFFFF;
  }
  
  label .form::before {
    display: block;
    position: absolute;
      content: '';
    border-radius: 100%;
    height: 15px;
    width: 15px;
    top: 5px;
      left: 5px;
    margin: auto;
    //   transition: background 0.25s linear;
    //   -webkit-transition: background 0.25s linear;
  }
  
  input[type=radio]:checked ~ .form {
    border: 5px solid #0DFF92;
  }
  
  input[type=radio]:checked ~ .form::before{
    background: #0DFF92;
  }
  
  input[type=radio]:checked ~ label{
    color: #0DFF92;
  }

  .container{
    display: block;
    position: relative;
    margin: 40px auto;
    height: auto;
    width: 500px;
    padding: 20px;
  }
  
  
  

