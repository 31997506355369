

@mixin buttonFormKoala ($f-size: 18px, $button-color: #D7091B) {
  padding: 9px 32px 9px 32px;
  color: #fff;
  font-size: 14px;
  font-weight: normal;
  background-color: #D7091B;
  border: none;
  border-radius: 20px;
  margin-top: 30px;
  // box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.3);
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  position: relative;
  img{
    width: 15px;
    margin-right: 10px;
  }
}

@mixin buttonHover ($colorHover: rgba(189, 15, 29, 0.9)) {
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  background-color: $colorHover;
}

.button-exp {
  color:red;

  &:hover {
    color:red;
    background: inherit;
    background-color: inherit;
  }
}

.button-exp2 {
  color:blue;

  &:hover {
    color:blue;
    background: inherit;
    background-color: inherit;
  }
}


button {
  @include buttonFormKoala(14px);
  :hover {
    background-color: inherit;
  }
}
.button-next {
  @include buttonFormKoala(14px);
  width: 40%;
  img{
    width: 15px;
    position: absolute;
    left: 75%;
  }
  &:hover {
    @include buttonHover;
  }
}
.button-back {
  @include buttonFormKoala(14px);
  width: 40%;
  background-color: transparent;
  color: rgba(4 , 5, 5 , .6);
  &:hover {
    transform: translateY(-3px);
    color: #000;
    font-weight: 900;
  }
}
.button-generate {
  @include buttonFormKoala(14px);
  width: 30%;
  background-color: #fff;
  border:1.5px solid #D7091B;
  color: #D7091B;
  margin-left: 0;
  &:hover {
    @include buttonHover;
    color: #fff;
  }
}

.button-back.generate {
  margin-right: 0;
  padding-right: 15px;
}

.button-next.quotation {
  width: 100%;
  margin-bottom: 0;
  margin-top: 15px;
}
.button-generate.quotation {
  width: 100%;
  margin-bottom: 0;
  margin-top: 15px;
}