.changes-modal-container {
    width: 600px;
    height: 350px;
    padding: 15px;
    padding-bottom: 0;
    display: grid;
    grid-template-rows: 20% 60% 20%;
    .changes-title-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        font-family: "Montserrat";
        padding-bottom: 15px;
        h3 {
            font-size: 1.3rem;
            font-weight: 800;
            margin-bottom: 15px;
        }
        h4 {
            font-weight: 600;
            margin-bottom: 15px;
        }
    }
    .changes-reason {
        .reasons-changes-input {
            width: 95%;
            height: 140px;
            border: none;
            background-color: rgba(4, 5, 5, 0.2);
            border-radius: 10px;
            margin: 10px 20px 20px 10px;        
            padding: 10px;
            text-align: start;
        }
    }
    .footer-form-generic-type.modal {
        padding: 5px;
        button {
            margin-top: 10px;
            margin-bottom: 0;
        }
    }
}