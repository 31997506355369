.main-container-generic-type-quotation.port {
    width: 100%;
    height: 100%;
    min-height: 435px;
    border-radius: 5px;
    .port-options-container {
        padding-top: 5%;
        height: 70%;
        overflow-y: scroll;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        .body-form-generic-type-data.port {
            height: 100%;
            overflow-y: scroll;
        }
        .form-admin-container.port {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            width: 100%;
            height: 100%;
            overflow-y: scroll;
            .body-form {
                .horizontal-inputs-container {
                    display: flex;
                    width: 100%;
                }
            }
        }
    }
    .footer-form-generic-type.port {
        display: flex;
        align-items: center;
        height: 20%;
    }
}