// variables de color: 

//primary
$dark-background: #040505;
$buttons-red: #D7091B;
$button-blue-primary:#0066B3;
//secondary
$button-red-secondary:#AA0413;
//neutrals
$button-neutrals: #707070;
$button-neutrals:#F8F9FA;
$light-background: #FFFFFF;
//alerts
$button-alert-error:#FF4C44;
$button-alert-warning:#FFDC1C;
$button-alert-sucess:#01A864;

$dark-font:700;

// Mixins:
@mixin Reset {
    margin: 0;
    padding: 0;
}

@mixin mainSectionKoala ($direction: row) {
    display: flex;
    /*height: 96vh;*/
    /*width: 100vw;*/
    width: auto;
    /*padding: 0;*/
    margin: 0;
    flex-direction: $direction;
}

@mixin mainTemplateKoala {
    height: 69vh;
    padding-left: 7%;
}

@mixin footerStyle {
    display: flex;
    justify-content: center;
    background-color: black;
    width: 100%;
    height: 4vh;
    color: #fff;
    text-align: center;
    align-items: center;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: $dark-font;
    font-size: 12px;
    position: fixed;
    z-index: 2;
    bottom: 0;
}

@mixin buttons {
    display: flex;
    justify-content: right;
    width: 100%;
}

@mixin buttonPillLoginKoala {
    display: inline-block;
    border-bottom-left-radius: 15px;
    border-top-left-radius: 15px;
    padding: 10px 0 9px;
    font-size: 15px;
    width: 120px;
}

@mixin buttonPillSignupKoala {
    display: inline-block;
    border-bottom-right-radius: 15px;
    border-top-right-radius: 15px;
    padding: 10px 0 9px;
    font-size: 15px;
    width: 120px;
}
@mixin buttonActive {
    background-image: linear-gradient(to bottom, $buttons-red, $dark-background 209%);
    color: $light-background;
    border: 1px solid $buttons-red;
    font-weight: $dark-font;
}
@mixin buttonInactive { 
    background-color: #fff;
    border: 1px solid #000;
    color: #000;
    font-weight: $dark-font;
    .link {
        color:#000;
        font-size: 15px;
    }
}

@mixin containerImageKoala {
    display: flex;
    margin: 0;
    width: 50%;
    height: 96%;
    background-color: #070508;
    align-items: center;
    justify-content: center;
    justify-self: left;
    position: absolute;
    left: 0;
    top: 0;
    .koala {
        width: 70%;
    }
}

@mixin formContainer ($pleft) {
    display: flex;
    flex-direction: column;
    align-items: space-around;
    background-color: $light-background;
    margin: 0;
    padding-right: 6%;
    padding-top: 3%;
    padding-left: $pleft;
    width: 50%;
    height: 96%;
    box-sizing: border-box;
    position: absolute;
    right: 0;
    top: 0;
    background-repeat: no-repeat;
    background-size: contain;
}

@mixin formDiv ($m-top: 0px, $p-top: 5px) {
    width: inherit;
    height: 70vh;
    margin-top: $m-top;
    padding-top: $p-top;
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: flex-start;
    color: $dark-background;
    font-weight: $dark-font;
    padding: 1px;
}

@mixin formBasicKoala ($paddingTop: 10%) {
    display: flex;
    flex-direction: column;
    height: 96vh;
    padding: 0;
    justify-self: right;
    margin: 0;
    padding-top: $paddingTop;
}

@mixin labelsKoala ($f-size: 20px, $m-top: 30px) {
    text-align: left;
    padding-left: 5px;
    font-size: $f-size;
    color: $dark-background;
    margin-bottom: 4px;
    margin-top: $m-top;
}

@mixin inputsKoala ($f-size: 18px, $pdg: 8px) {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid rgb(219, 219, 219);
    padding: $pdg;
    padding-left: 5px;
    width: 100%;
    background-color: $light-background;
    color: $dark-background;
    font-size: $f-size;
    ::placeholder {
        color: #a3a3a3;
    }
}

@mixin buttonFormKoala ($f-size: 18px, $button-color: $buttons-red) {
    padding: 9px 32px 9px 32px;
    color: #fff;
    font-size: $f-size;
    font-weight: $dark-font;
    background-color: $button-color;
    border: none;
    border-radius: 10px;
    margin-top: 30px;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.3);
}

@mixin centerItems ($f-direction: column) {
    display: flex;
    flex-direction: $f-direction;
    justify-content: center;
    align-items: center;
}

@mixin tables () {
    background-color: #fff;
    border-radius: 10px;
}

@mixin cardItemBase () {
    width: 20%;
    min-width: 220px;
    height: 85%;
    max-height: fit-content;
    border-radius: 20px;
    background-color: #fff;
    padding: 10px;
    margin: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
}

@mixin cardsItemsContainer () {
    overflow-x: scroll;
    margin-top: 80px;
    margin-bottom: 30px;
    padding-left: 130px;
    padding-right: 100px;
    display: flex;
    width: 100%;
    height: 100%;
    min-height: 160px;
}

@mixin optionsIcons () {
    filter: invert(75%);
    margin-right: 10px;
}

